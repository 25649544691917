import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'muse-ui/lib/styles/base.less';
import { Button, AppBar,Icon,Popover,TextField,Picker,Snackbar,Dialog,Tabs, Drawer, List,Stepper } from 'muse-ui';
import 'muse-ui/lib/styles/theme.less';
import 'muse-ui/dist/muse-ui.css'
import VueLazyload from 'vue-lazyload'


import api from './api';

import store from './utils/store'

import Toast from 'muse-ui-toast';

// import MuseUI from 'muse-ui';
// import 'muse-ui/dist/muse-ui.css';

Vue.prototype.$store = store
Vue.prototype.$api = api

Vue.config.productionTip = false

Vue.use(Button);
Vue.use(AppBar);
Vue.use(Icon);
Vue.use(Popover);
Vue.use(TextField);
Vue.use(Snackbar)
Vue.use(Picker);
Vue.use(Dialog)
Vue.use(Tabs)
Vue.use(Drawer)
Vue.use(List)
Vue.use(Stepper)
Vue.use(Toast,{
  position: 'top',               // 弹出的位置
  time: 2000,                       // 显示的时长
  closeIcon: 'close',               // 关闭的图标
  close: true,                      // 是否显示关闭按钮
  successIcon: 'check_circle',      // 成功信息图标
  infoIcon: 'info',                 // 信息信息图标
  warningIcon: 'priority_high',     // 提醒信息图标
  errorIcon: 'warning'              // 错误信息图标
});

Vue.use(VueLazyload, {
  loading: require('./assets/loading.gif'),//加载中图片，一定要有，不然会一直重复加载占位图
  error: require('./assets/err.jpg')  //加载失败图片
});



// Vue.prototype.$toast = Toast

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
