
const icons = [
    {color: '#E22018', name: 'favorite'},
    {color: '#FFC343', name: 'cake'},
    {color: '#42C39A', name: 'photo'},
    {color: '#A468F7', name: 'wb_sunny'},
    {color: '#F7C168', name: 'directions_walk'},
    {color: '#F78468', name: 'local_activity'},
    {color: '#F768AD', name: 'local_bar'},
    {color: '#F7D068', name: 'restaurant'},
    {color: '#56A1BF', name: 'satellite'},
    {color: '#C0785F', name: 'headset'},
    {color: '#61BF56', name: 'brightness_2'},
    {color: '#5F646F', name: 'laptop_mac'},
    {color: '#BA5CFA', name: 'settings_system_daydream'},
    {color: '#546FE5', name: 'business_center'},
    {color: '#00C4F6', name: 'add_alarm'},
    {color: '#FF7DB3', name: 'beach_access'},
    {color: '#C07860', name: 'content_cut'},
    {color: '#2AD8B6', name: 'voicemail'},
    {color: '#5A6BED', name: 'pool'},
    {color: '#FF7E44', name: 'rv_hookup'},
    {color: '#57D530', name: 'child_care'},
    {color: '#2AD8B6', name: 'spa'},
    {color: '#43D3E3', name: 'loyalty'},
]

export {
    icons
}