<template>
  <div class="home" >

 <div class="ranImageWrap">
       <mu-button fab small color="pink" @click="goPage"> <mu-icon value="grade"></mu-icon></mu-button>
  </div>

  <mu-button fab small  color="orange" @click="onRun" > <mu-icon size="25" value="face"></mu-icon></mu-button>


  <div class="mohu" :style="{filter: ismoHu ? 'blur(1.33333vw)': 'blur(0em)', opacity: ismoHu? 0.5:1}" v-touch:swipeleft="leftSlide">
    <!-- <mu-button icon color="rgba(0,0,0,.54)" class="delAll" @click="delAll">
      <mu-icon value="grade"></mu-icon>
    </mu-button> -->


  <img src="../assets/images/time.png" class="time1" alt="">
  <img src="../assets/images/time.png" class="time2" alt="">
      <div class="title" >
        <div class="time" @click="onDay">TIME <span class="p1">陪伴</span></div>
        <div class="add"> 
          <mu-button fab  color="#5D6EF0" small @click="add"> <mu-icon value="add" ></mu-icon> </mu-button>
         
      </div>
    </div>

    

    <div class="content">
      <div class="part today">
        <div class="p1">置顶  {{ top[0] && top[0].tag | formatTime}}</div>
        <div class="box"  v-if="top.length">
          <div class="item" v-for="(item, key) in top" :key="key" :style="{backgroundColor: item.color} " @click="showDetail(item)">
            <img class='bgImage' :src='item.image' />
            <div class="icon">
               <mu-icon :value="item.name"></mu-icon>
            </div>
            <div class="name">
              {{item.title}}
            </div>
            <div class="rest" :style="{color: item.color} " v-if="item.rest !== '0天'">
              {{item.rest}}
            </div>
            <div class="date">
              {{item.date | formatDateFilter}}
            </div>
          </div>
        </div>

        <div v-else class="p3">最重要的事只有一件。</div>
      </div>

      

      <div class="part today">
        <div class="p1">今日</div>
        <div class="box"  v-if="today.length">
          <div class="item" v-for="(item, key) in today" :key="key" :style="{backgroundColor: item.color,  display: item.top === 1 ?'none' :'block'} " @click="showDetail(item)">
            <img class='bgImage' :src='item.image' />
            <div class="icon">
               <mu-icon :value="item.name"></mu-icon>
            </div>
            <div class="name">
              {{item.title}}
            </div>
            <!-- <div class="rest" :style="{color: item.color} ">
              {{item.rest}} 
            </div> -->
            <div class="date">
              {{item.date | formatDateFilter}}
            </div>
          </div>
        </div>

        <div v-else class="p3">未来太远，过去太迟，活在当下最好。</div>
      </div>
      
       <div class="part future">
        <div class="p1">将来</div>
         <div class="box" v-if="future.length">
          <div class="item" v-for="(item, key) in future" :key="key"   :style="{backgroundColor: item.color, display: item.top === 1 ?'none' :'block'} " @click="showDetail(item)">
            <img class='bgImage' :src='item.image' />
            <div class="icon">
               <mu-icon :value="item.name"></mu-icon>
            </div>
            <div class="name">
              {{item.title}}
            </div>
            <div class="rest" :style="{color: item.color} ">
              {{item.rest}}
            </div>
            <div class="date">
              {{item.date | formatDateFilter}}
            </div>
          </div>
        </div>
         <div v-else class="p3">海阔凭鱼跃，天高任鸟飞。</div>
      </div>

      
       <div class="part past">
        <div class="p1">过去</div>
         <div class="box" v-if="past.length">
          <div class="item" v-for="(item, key) in past" :key="key" :style="{backgroundColor: item.color,  display: item.top === 1 ?'none' :'block'} " @click="showDetail(item)">
            <img class='bgImage' :src='item.image' />
            <div class="icon">
               <mu-icon :value="item.name"></mu-icon>
            </div>
            <div class="name">
              {{item.title}}
            </div>
            <div class="rest" :style="{color: item.color} ">
              {{item.rest}}
            </div>
            <div class="date">
              {{item.date | formatDateFilter}}
            </div>
          </div>
        </div>
        <div v-else class="p3">曾经沧海难为水，除却巫山不是云。</div>
      </div>
    </div>
  </div>
  

  <div class="formWrap" v-if="open"  @click.self="close">
    <div class="form" :class="[openAn?'formShow':'formHide']"  >
         <mu-button fab :color="icons[currentIcon].color" small class="pos" @click="submit">
          <mu-icon value="check"></mu-icon>
        </mu-button>
        <mu-text-field :error-text="errorText" v-model="title" placeholder="输入主题"  full-width solo  max-length="15"></mu-text-field>
        <mu-date-picker type="date" :date.sync="date1"  ></mu-date-picker>
        <div class="iconsWrap">
            <div class="icons" :style="{width: `${(icons.length / 2)*15}vw`}">
                 <div class="item" @click="changeIcons(index)" v-for="(item,index)  in icons" :key="index" :style="{backgroundColor: item.color}"> 
                    <div class="check" v-if="currentIcon === index">
                      <img src="../assets/images/right.png" alt="">
                    </div>
                     <mu-icon :value="item.name"></mu-icon>
                   </div>
            </div>
        </div>
    </div>
  </div>



  <div class="detailWrap" v-if="isDetail">
       <div class="detail" :style="{backgroundColor: currentItem.color} "  :class="[openAnDetail?'detailShow':'detailHide']" >

          <div v-if="currentItem.tag  === 0" class="p1">今天</div>
          <div v-if="currentItem.tag  === 1" class="p1">
           <span class="p4">累计</span><span class="p5">{{currentItem.rest.substring(0,currentItem.rest.length - 1)}}</span><span class="p4">天</span>
          </div>

          <div v-if="currentItem.tag  === 2" class="p1">
            <span class="p4">还有</span><span class="p5"> {{currentItem.rest.substring(0,currentItem.rest.length - 1)}}</span><span class="p4">天</span>
          </div>

         <div class="p2">{{currentItem.title}}</div>
         <div class="p3">{{currentItem.date | formatDateFilter}}</div>


        <div class="descBox">
          <mu-icon :value="currentItem.name" class="desc"></mu-icon>
            <mu-icon :value="currentItem.name" class="desc"></mu-icon>
              <mu-icon :value="currentItem.name" class="desc"></mu-icon>
             <mu-icon :value="currentItem.name" class="desc"></mu-icon>
        </div>


       </div>



      <div class="option" :class="[openAnOption?'detailShowc':'detailHidec']">
        <div class="buttons">
          <div class="button" @click="editItem">
            <img src="../assets/images/edit.png" alt=""><br>
            编辑
          </div>
          <div class="button" @click="setTop" v-if="currentItem.top === 0">
            <img class="top" src="../assets/images/top.png" alt=""><br>
            置顶
          </div>
           <div class="button" @click="cancelTop" v-else>
            <img class="top" src="../assets/images/top1.png" alt=""><br>
            取消
          </div>
          <div @click="delItem">
            <img src="../assets/images/del.png" alt=""><br>
            删除
          </div>

        </div>
        <div @click="closeDetail">
          <img class="back" src="../assets/images/back.png" alt="">
        </div>
      </div>

  </div>

  </div>
</template>

<script>
import {icons} from '../utils/decoration'
import { formatDate,dateDiff } from '../utils'
export default {
  directives:{
    //滑动指令
	touch:{
            bind: function (el, binding) {
            var touchType = binding.arg; //传入的模式 press swipeRight swipeLeft swipeTop swipeDowm Tap
            var timeOutEvent = 0;
            var direction = '';
            //滑动处理
            var startX, startY;
        
            //返回角度
            function GetSlideAngle(dx, dy) {
                return Math.atan2(dy, dx) * 180 / Math.PI;
            }
        
            //根据起点和终点返回方向 1：向上，2：向下，3：向左，4：向右,0：未滑动
            function GetSlideDirection(startX, startY, endX, endY) {
                var dy = startY - endY;
                var dx = endX - startX;
                var result = 0;
        
                //如果滑动距离太短
                if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
                return result;
                }
        
                var angle = GetSlideAngle(dx, dy);
                if (angle >= -45 && angle < 45) {
                result = 'swiperight';
                } else if (angle >= 45 && angle < 135) {
                result = 'swipeup';
                } else if (angle >= -135 && angle < -45) {
                result = 'swipedown';
                }
                else if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
                result = 'swipeleft';
                }
                return result;
            }
        
            el.addEventListener('touchstart', function (ev) {
                startX = ev.touches[0].pageX;
                startY = ev.touches[0].pageY;
        
                //判断长按
                timeOutEvent = setTimeout(() =>{
                timeOutEvent = 0 ;
                if(touchType === 'press'){
                    binding.value()
                }
                } , 500);
        
            }, false);
        
            el.addEventListener('touchmove' , function () {
                clearTimeout(timeOutEvent)
                timeOutEvent = 0;
            });
        
            el.addEventListener('touchend', function (ev) {
                var endX, endY;
                endX = ev.changedTouches[0].pageX;
                endY = ev.changedTouches[0].pageY;
                direction = GetSlideDirection(startX, startY, endX, endY);
        
                clearTimeout(timeOutEvent)
        
                switch (direction) {
                case 0:
                    break;
                case 'swipeup':
                    if(touchType === 'swipeup'){
                        binding.value()
                    }
                    break;
                case 'swipedown':
                    if(touchType === 'swipedown'){
                        binding.value()
                    }
                    break;
                case 'swipeleft':
                    if(touchType === 'swipeleft'){
                        binding.value()
                    }
                    break;
                case 'swiperight':
                    if(touchType === 'swiperight'){
                        binding.value()
                    }
                    break;
                }
                    }, false)
            }
},
}, 
  name: 'Home',
  components: {
  },
  filters:{
    formatDateFilter(v){
      return formatDate(v, 'yyyy年MM月dd')
    },
    formatTime(v) {
      return  {0: '今日', 1: '过去', 2: '将来'}[v]
    }
  },
  data(){
    return {
      icons,
      ismoHu:false,
      open: false,
      openAn:false,
      trigger: null,
      isDetail:false,
      openAnDetail:false,
      openAnOption:false,
      title:'',
      date1:new Date(),
      currentIcon:0,
      errorText: '',
      top:[],
      today: [
  
      ],
      future: [],
      past:[],
      currentItem:{},
      currentItemIndex:0,
      currentId:''
    }
  },
  methods: {
    add(){
      this.open = true
      this.openAn = true
      this.ismoHu = true
      // 初始化

      this.date1 = new Date()
      this.title = ''
      this.currentIcon = 0
      this.currentId = ''
    },
    close(){
      this.openAn = false
      this.ismoHu = false
      setTimeout(()=>{
        this.open = false
      }, 500)
    },
    changeIcons(index){
      this.currentIcon = index
    },

  leftSlide(){
    this.$router.push('/day')
  },

  goPage(){
    this.$router.push('/ran')
  },

    dealList(list){
      const date = new Date()
      const day = formatDate(date, 'yyyy-MM-dd')
      const timestamp1 =(new Date(day)).valueOf();
      const today = []
      const future = []
      const past = []

      // const afterList = list.filter(item => item.top === 0)
      list.forEach(item => {
        // console.log(item)
        const itemDay = formatDate(item.date, 'yyyy-MM-dd')
        const timestamp2 =(new Date(itemDay)).valueOf();
        item.rest = dateDiff(timestamp1, timestamp2) + '天'
        if(timestamp1 === timestamp2) {
          today.unshift({...item, tag: 0})
          return
        }
          if(timestamp1 >= timestamp2) {
          past.unshift({...item, tag: 1})
        }else {
          future.unshift({...item, tag: 2})
        }
      })

      const aa = [...today, ...past, ...future]
  // console.log(list,33)
     this.top = aa.filter(item => Number(item.top) === 1)

      this.today = today
      this.future = future
      this.past = past
    },
    submit(){
      if(!this.title) {
        this.$toast.error('主题不能为空');
        return
      }

      if(this.currentId) {
      const values =  {id: this.currentItemIndex, title: this.title, currentIcon:this.currentIcon, ...this.icons[this.currentIcon], date: this.date1.valueOf(), createDate: new Date().valueOf() , top: this.currentItem.top }
       this.$api.editEvent({data: values}).then(()=>{
          this.getList()
          this.close();
        })
      }else {
        const values = {user:this.$store.user, id: new Date().valueOf() + Math.floor(Math.random()*5), title: this.title, currentIcon:this.currentIcon, ...this.icons[this.currentIcon], date: this.date1.valueOf(), createDate: new Date().valueOf() , top: 0 }
        this.$api.addEvent({data: values}).then(()=>{
          this.getList()
          this.close();
        })
      }
    },
     showDetail(item){
      this.ismoHu = true
      this.isDetail = true
      this.openAnDetail = true
      this.openAnOption = true
      this.currentItem = item
      this.currentItemIndex = item.id
    },

    delItem(){
    const values = {id:this.currentItemIndex}
    this.$api.delEvent({data:values}).then(()=>{
       this.getList()
       this.closeDetail()
    })
    },

  setTop(){
    const values = {id:this.currentItemIndex, user: this.$store.user}
    this.$api.setTop({data:values}).then(()=>{
       this.getList()
       this.currentItem.top = 1
    })
  },

  editItem(){
      this.closeDetail()
      this.add()
      this.title = this.currentItem.title
      this.currentIcon = this.currentItem.currentIcon
      this.date1 = new Date(this.currentItem.date)
      this.currentId = this.currentItem.id

  },

  cancelTop(){
    const values = {id:this.currentItemIndex}
    this.$api.eventCancelTop({data:values}).then(()=>{
       this.getList()
       this.currentItem.top = 0
    })

  },

    closeDetail(){
        this.openAnDetail = false
        this.openAnOption = false
        this.ismoHu = false
        setTimeout(()=>{
         this.isDetail = false
        }, 500)
    },

    delAll(){
      this.$store.list = []
      this.dealList([]);
    },
     login(){
      const userName = this.$store.user
      if(!userName) {
        this.$router.push('/user')
      }
     
    },
   onDay(){
      this.$router.push('/day')
    },
    onRun(){
      this.$router.push('/run')
    },
    async getList(){
      const name = this.$store.user;
      const list = await this.$api.event({data: {name}})
      console.log(list,3)
      this.dealList(list);
    },
 
  },
 

 
  mounted(){
    this.login();
    this.getList()

  }
}
</script>

<style lang="less" >

.bgImage {
  position:absolute;
  width: 250px;
  left:0;
  top: -35px;
  opacity:0.8;

}

.ranImageWrap {
  width: 140px;
  height: 40px;
  position: absolute;
  z-index: 99;
  border-radius: 50%;
  text-align: center;
  line-height: 3.7;
  right: -30px;
}

.ranImage {
  width: 60%;
}
  

.time1 {
  position: fixed;
  z-index: -1;
  opacity: 0.3;
  width: 80%;
}


.delAll {
  position: absolute;
  right: 100px;
}

.time2 {
  z-index: -2;
  width:20%;
}
  .mohu {
    transition: all 1s;
  }
  .home {
    padding: 20px 20px;
    min-height: 100vh;
    background: #f7f7f7;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .time {
      font-size: 42px;
      font-weight: bolder;
      color: #040404;

      .p1 {
        font-size: 20px;
      }
    }
  }

  .content {
    padding-bottom: 50px;
    background: #F7F7F7;
    min-height: 100vh;
    .p1 {
      font-weight: bold;
      color: #424242;
      position: sticky;
      top: 0;
      background: #f7f7f7;
      padding: 10px 0;
      z-index:3 ;
    }

    .p3 {
       color: #676767;
       font-size: 14px;

    }

    .part {
      margin-top: 20px;
    }

    .box {
      display: flex;
      flex-wrap: wrap;
    justify-content:space-between;
    
      .item {
        width: 48%;
        text-align: center;
        position: relative;
        margin-top: 10px;
        border-radius: 10px;
        padding: 10px 0;
        color: #fff;
        overflow:hidden;

       
      }
      .rest {
        position: absolute;
        top:  10px;
        left: 10px;
        background: #fff;
        font-size: 12px;
        padding: 0 8px;
        border-radius: 5px;
        max-width: 60px;
        text-overflow:ellipsis;
        overflow: hidden;
         white-space: nowrap; 
      }

      .name {
        width: 90%;
        display: inline-block;
        text-overflow:ellipsis;
        overflow: hidden;
         white-space: nowrap; 
         font-size: 16px;
         position:relative;
         z-index: 10px;
         text-shadow:2px 2px 1px rgba(0,0,0,.5);
      }

      .icon {
        position: relative;
        top: 5px;
      }

      .date {
        font-size: 12px;
          position:relative;
         z-index: 10px;
      }
    }
  }


  .detailWrap {
      width: 100%;
      height: 100vh;
      position: absolute;
      left:0;
      top:0;
      z-index: 2;
      overflow: hidden;
      transition: all 1s;
      .p1 {
        font-size: 48px;
        font-weight: bolder;
      }

      .p2 {
        font-size: 20px;
        font-weight: bold;
      }

      .p3 {
        font-size: 14px;
        opacity: 0.8;
      }

      .p4 {
        font-size: 16px;
        font-weight: normal;
      }

      .p5 {
         font-size: 58px;
        font-weight: bolder;
        padding: 0 10px;
      }
      .detail {
        width: 80%;
        padding: 20px 0;
         position: fixed;
         left: 50%;
           top: -500px;
         transform: translateX(-50%);
         z-index: 9;
         text-align: center;
         color: #fff;
         border-radius: 15px;
         overflow: hidden;
      }

      .option {
         width: 80%;
        padding: 20px 0;
         position: fixed;
         left: 50%;
          bottom: -700px;
         transform: translateX(-50%);
         z-index: 9;
         text-align: center;
         border-radius: 15px;
         overflow: hidden;
        


        .buttons {
           display: flex;
             justify-content: space-around;
            align-items: baseline;
             img {
             width: 35px;
           }

          
          }
          
          .top{
            width: 45px !important;
          }

          .back {
            width: 28px;
            margin-top: 50px;
          }

      }

      .descBox {
        position: relative;
      }

      .desc {
        position: absolute;
        opacity: 0.8;
        font-size: 14px;

        &:nth-child(1) {
          right: -6px;
          bottom: 8px;
        }
        &:nth-child(2) {
          right: 20px;
          bottom: 15px;
        }
        &:nth-child(3) {
          right: 10px;
          bottom: -10px;
        }
         &:nth-child(4) {
          right: 40px;
          bottom: -25px;
        }
      }
  }


  .detailShow {
    animation: top .5s forwards;
  }

  .detailHide {
    animation: bottom .5s forwards;
  }

  @keyframes top {
    0%{
      top: -500px
    }
    100% {
      top: 20%;
    }
  }

   @keyframes bottom {
    0%{
      top: 20%;
    }
    100% {
      top: -500px
    }
  }

  .detailShowc {
    animation: ctop .5s forwards;
  }

  .detailHidec {
    animation: cbottom .5s forwards;
  }


  @keyframes ctop {
    0%{
      bottom: -700px
    }
    100% {
      bottom: 200px
    }
  }

   @keyframes cbottom {
    0%{
      bottom:200px;
    }
    100% {
      bottom: -700px
    }
  }



  .formWrap {
    width: 100%;
    height: 100vh;
    position: fixed;
    left:0;
    top:0;
    z-index: 2;
    overflow: hidden;
    transition: all 1s;
    .form {
        width: 100%;
        background: #fff;
        position: fixed;
        left: 0;
        box-sizing: border-box;
        padding: 10px;
        transition: all 1s;
        bottom: -100%;
        transition-delay: 3s;
      }
    .pos {
      position: absolute;
      right: 10px;
      top: -20px
    }
  }

  .formShow {
    animation: show .5s forwards;
  }

   .formHide {
    animation: hide .5s forwards;
  }






  .iconsWrap {
       overflow-x: auto;
       overflow-y: hidden;
    .icons {
      height: 110px;
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 40px;
        height: 40px;
        background: blue;
        border-radius: 50%;
        line-height: 40px;
        text-align: center;
        color: #fff;
        margin-right: 15px;
        margin-bottom: 10px;
        font-size: 33px;
        position: relative;
      }
      .check {
        position: absolute;
        border-radius: 50%;
        bottom: -2px;
        right: -2px;
        color: #222;
        font-weight: bolder;
        background: #fff;
        width: 15px;
        height: 15px;
        line-height: 0;

        img {
          width: 15px;
          vertical-align: middle;
        }

      }
    }
  }

  .iconsWrap::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
input::-webkit-input-placeholder {color: #B9B9B9; font-size: 26px; font-weight: bold;}
.mu-text-field-input {font-size: 26px; font-weight: bold; color: #424242;}
.mu-picker {
  margin-top: 20px;
  width: 100%;
}
.mu-date-display-monthday {
  font-size: 20px;
}
.mu-datepicker-monthday {
  height:180px;
}
.mu-picker-display {
  display: none;
}

@keyframes show {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}


@keyframes hide {
  0% {
    bottom: 0;
  }
  100% {
    bottom:-100%;
  }
}


.footer {
  text-align: center;
  padding: 20px 0;
  color: #ccc;
  width: 100%;
}

</style>