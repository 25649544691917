
//--------------------------------------
//  该文件为 webpack 自动生成，切勿手动修改
//--------------------------------------
import createApis from './ajax';
import index from './config/index';

const apis = {
    ...index
};

const api = createApis.regist(apis);
export default api;