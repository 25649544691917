function formatDate(dateNum, fmt = 'yyyy-MM-dd hh:mm:ss') {
    const date = typeof dateNum === 'number' ? new Date(dateNum) : dateNum;
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
      }
    }
    return fmt;
  }
  

  function dateDiff(sDate1, sDate2) {  //sDate1和sDate2是yyyy-MM-dd格式 
    const iDays = parseInt(Math.abs(sDate1 - sDate2) / 1000 / 60 / 60 / 24); //把相差的毫秒数转换为天数 
    return iDays;  //返回相差天数 
}


 //获取两日期之间日期列表函数
 function getdiffdate(stime,etime){
  //初始化日期列表，数组
  var diffdate = new Array();
  var i=0;
  //开始日期小于等于结束日期,并循环
  while(stime<=etime){
      diffdate[i] = stime;
      //获取开始日期时间戳
      var stime_ts = new Date(stime).getTime();
      //增加一天时间戳后的日期
      var next_date = stime_ts + (24*60*60*1000);
      //拼接年月日，这里的月份会返回（0-11），所以要+1
      var next_dates_y = new Date(next_date).getFullYear()+'-';
      var next_dates_m = (new Date(next_date).getMonth()+1 < 10)?'0'+(new Date(next_date).getMonth()+1)+'-':(new Date(next_date).getMonth()+1)+'-';
      var next_dates_d = (new Date(next_date).getDate() < 10)?'0'+new Date(next_date).getDate():new Date(next_date).getDate();
      stime = next_dates_y+next_dates_m+next_dates_d;
      //增加数组key
      i++;
  }
  return diffdate
}

function getweekday(date){
  var weekArray = new Array("日", "一", "二", "三", "四", "五", "六");
  var week = weekArray[new Date(date).getDay()];
  return week;
}

function addDate(date,days){ 
  var d=new Date(date); 
  d.setDate(d.getDate()+days); 
  var m=d.getMonth()+1; 
  return formatDate(new Date(d.getFullYear()+'-'+m+'-'+d.getDate()), 'yyyy-MM-dd'); 
} 

function GetNumberOfDays(date1,date2){//获得天数
  //date1：开始日期，date2结束日期
  var a1 = Date.parse(new Date(date1));
  var a2 = Date.parse(new Date(date2));
  var day = parseInt((a2-a1)/ (1000 * 60 * 60 * 24));//核心：时间戳相减，然后除以天数
  return day + 1
}

export {
  GetNumberOfDays,
    formatDate,
    dateDiff,
    getdiffdate,
    getweekday,
    addDate
}