const apis = {
  login: 'post /api/time/regist',
  event: 'get /api/time/event',
  addEvent: 'post /api/time/event',
  editEvent: 'post /api/time/eventEdit',
  setTop:'post /api/time/eventSetTop',
  eventCancelTop: 'post /api/time/eventCancelTop',
  delEvent: 'post /api/time/delEvent',
  addAllDayEvent: 'post /api/time/addAllDayEvent',
  getAllDayEvent: 'post /api/time/getAllDayEvent',
  setEventCheck: 'post /api/time/setEventCheck',
  setAll: 'post /api/time/setAll',
  
  
};

/* eslint-disable */
module.exports = apis['default'] = apis;
