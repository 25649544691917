<template>
  <div id="app">
     <transition  v-if="direction == 'fade'">
        <router-view />
      </transition>
     <div v-else>
        <transition  v-if="direction==='slide-right'">
          <router-view class="appView"/>
        </transition>
        <transition :name="direction" :mode="direction==='fade'?'':'in-out'" >
          <router-view class="appView"/>
        </transition>
     </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    direction: ""
  }),
  watch: {
    $route(to, from) {
      if(from.path === '/user' || to.path === '/user' || to.path === '/ran'|| to.path === '/run') {
        this.direction = 'fade'
        return
      }
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      if (to.path == "/") {
        this.direction = "slide-right";
      } else if (from.path == "/") {
        this.direction = "slide-left";
      }else{
        this.direction = toDepth < fromDepth ? "slide-right" : "slide-left";
      }
    }
  },
  mounted(){
    console.log(1000)
  }
};
</script>
<style>
#app {
  overflow-x: hidden;
}
.appView {
  position: absolute;
  width:100%;
  transition: transform 0.3s ease-out;
}
.slide-left-enter{
  transform: translate(100%, 0);
}
.slide-left-leave-active{
  transform: translate(-50%, 0);
}
.slide-right-enter {
  transform: translate(-50%, 0);
}
.slide-right-leave-active{
  transform: translate(100%, 0);
}
</style>