import store from 'store';
const PREFIX = 'timeStore/';
// 注册 key 值
const keys = ['list', 'user'];
const local = {
  store,
  clearAll: () => store.clearAll(),
};
const props = keys.reduce((pre, key) => {
  const keyName = `${PREFIX}${key}`;
  pre[key] = {
    enumerable: true,
    get() {
      return store.get(keyName);
    },
    set(value) {
      return store.set(keyName, value);
    },
  };
  return pre;
}, {});
Object.defineProperties(local, props);
export default local;
