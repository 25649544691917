import Ajax from '@qiejs/ajax';
import axios from 'axios';
import store from '../utils/store';
import Message from 'muse-ui-message';

axios.defaults.timeout = 60 * 1000;
// axios.defaults.baseURL =  'https://ai.ok-bug.com/';

axios.defaults.baseURL =  'http://101.43.248.121:2000/';

// axios.defaults.withCredentials = true;

// ----------------- 拦截器
// 添加 请求 拦截器
axios.interceptors.request.use(
  (config) => {
    // const { token } = store;
    // if (token) config.headers.token = token;
    return config;
  },
  error => {
    // 检查网络连接
    console.log('请检查网络连接: ', error.message);
    // message.info('网络连接失败');
    return new Promise(() => {});
  },
);

// 添加 响应 拦截器
axios.interceptors.response.use(response => {
  // webpack 热更新时，response 为上一次 return 的 data;
  const { code, data } = response.status === 200 ? response.data : { data: response, code: 200 };

  // 正常数据
  if (code === 1) return data;

  const { href } = window.location;
  if (code === 20004) {
    store.token = '';
    // message.error('登录失效，请重新登录');
    const notInLoginPage = href.indexOf('next') === -1;
    if (notInLoginPage) {
      window.location.href = `/#/?next=${encodeURIComponent(window.location.href.split('#')[1])}`;
    }
    return new Promise(() => {});
  }

  // 服务端错误 【只抛出 正常响应的错误数据】
  return Promise.reject(response.data);
});

const createAjax = Ajax({
  axios,
  dataType: 'json',
  catch: err => {
    Message.alert( `${err.message}`);
  },
});
export default createAjax;
